"use client"
import React, {ReactNode, useState, useCallback, useRef, useEffect, Fragment} from "react"
import {usePathname} from "next/navigation"
import NProgress from "nprogress"
import RouterProgressionContext from "@/contexts/RouterProgressionContext"
import NavigationProgressBar from "./NavigationProgressBar"
import Script from "next/script"

declare const window: Window & {dataLayer: Record<string, unknown>[]}
declare global {
    interface Window {
        intercomSettings: any
        Intercom: any
        gtagSettings: any
        // dataLayer: Record<string, unknown>[];
        gtag: any;
        fbq: any;
    }
}

export default function RouterEventWrapper({children}: {children: ReactNode}) {
    const onStart = useCallback(() => NProgress.start(), [])
    const onComplete = useCallback(() => NProgress.done(), [])
    const [isChanging, setIsChanging] = useState(false)
    const pathname = usePathname()
    const isAppPath = /^\/(.*\/)?app/.test(pathname)
    const onCompleteFresh = useRef(onComplete)
    const onStartFresh = useRef(onStart)
    useEffect(() => setIsChanging(false), [pathname])

    useEffect(() => {
        if (isChanging) onStartFresh.current()
        else onCompleteFresh.current()
    }, [isChanging])

    // Handle tracking parameters and store in cookies
    const setCookie = useCallback((n: string, v: string) => {const d = new Date();d.setTime(d.getTime() + 7776e6);document.cookie = `${n}=${v}; expires=${d.toUTCString()}; path=/`}, [])
    useEffect(() => {
        if (typeof window === "undefined") return
        const params = new URLSearchParams(window.location.search)
        // Store UTM params
        const trackingParams = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "gclid", "gbraid", "wbraid", "dclid", "fbclid"]
        trackingParams.forEach((param) => {
            const value = params.get(param)
            if (value) setCookie(param, value)
        })

        // Store ad IDs with source prefix
        const ref = params.get("ref")
        if (ref) setCookie("ref", ref)
        // If click is from adweek, set gclid to adweek_gclid
        const gclid = params.get("gclid")
        if (gclid) setCookie("gclid", `${ref?.includes("creditexaminer") ? "creditexaminer" : "google"}_${gclid}`)
        
        const ttclid = params.get("ttclid")
        if (ttclid) setCookie("ttclid", `tiktok_${ttclid}`)
        
        const fbclid = params.get("fbclid")
        if (fbclid) setCookie("fbclid", `meta_${fbclid}`)
    }, [setCookie])

    // TikTok Pixel
    // useEffect(() => {
    //     if (typeof window === 'undefined' || typeof document === 'undefined') return;
    //     setTimeout(() => {
    //         (function (w, d, t) {
    //             if (typeof w !== 'undefined') {
    //                 (w as any).TiktokAnalyticsObject = t;
    //                 var ttq = (w as any)[t] = (w as any)[t] || [];
    //                 ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"];
    //                 ttq.setAndDefer = function (t, e) {
    //                     t[e] = function (...args) { // Use rest parameters here
    //                         t.push([e].concat(args)) // args is already an array, so you can use it directly
    //                     }
    //                 };
    //                 for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
    //                 ttq.instance = function (t) {
    //                     for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
    //                     return e
    //                 };
    //                 ttq.load = function (e, n) {
    //                     var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
    //                     ttq._i = ttq._i || {};
    //                     ttq._i[e] = [];
    //                     ttq._i[e]._u = i;
    //                     ttq._t = ttq._t || {};
    //                     ttq._t[e] = +new Date;
    //                     ttq._o = ttq._o || {};
    //                     ttq._o[e] = n || {};
    //                     var o = document.createElement("script");
    //                     o.type = "text/javascript";
    //                     o.async = true;
    //                     o.src = i + "?sdkid=" + e + "&lib=" + t;
    //                     var a = document.getElementsByTagName("script")[0];
    //                     a.parentNode.insertBefore(o, a)
    //                 };
    //                 ttq.load('CN9RGOBC77U2LHT34980');
    //                 ttq.page();
    //             }
    //         })(window, document, 'ttq');
    //     }, 4000);
    // }, []);

    return (
        <RouterProgressionContext.Provider value={() => setIsChanging(true)}>
            <NavigationProgressBar />

            <div className={`flex min-h-screen flex-col`} style={{background: "rgb(250, 250, 250)"}}>
                {children}
            </div>

            {/* Meta Pixel */}
            {process.env.NODE_ENV === "production" && (
                <>
                    <Script
                        strategy="afterInteractive"
                        id="meta-pixel"
                        dangerouslySetInnerHTML={{
                            __html: `
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '${process.env.NEXT_PUBLIC_META_PIXEL_ID}');
                            fbq('track', 'PageView');
                            
                            // Store Facebook Browser ID (fbp) in cookie for server-side tracking
                            if (document.cookie.indexOf('_fbp=') !== -1) {
                              document.cookie.split(';').forEach(function(c) {
                                let cookie = c.trim();
                                if (cookie.startsWith('_fbp=')) {
                                  const fbpValue = cookie.substring(5);
                                  document.cookie = "fbp=" + fbpValue + "; max-age=7776000; path=/";
                                }
                              });
                            }
                        `
                        }}
                    />
                    <noscript>
                        <img height="1" width="1" style={{display: "none"}} src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_META_PIXEL_ID}&ev=PageView&noscript=1`} />
                    </noscript>
                </>
            )}
        </RouterProgressionContext.Provider>
    )
}
